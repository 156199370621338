import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"
import { seoDataFromPage } from "components/utils/data-formatting"

const AboutPage = ({ pageContext, data: queryData }) => {
  const { acf } = queryData.wordpressPage
  const { hero, under_hero } = acf.about

  const { title } = pageContext
  const meta = seoDataFromPage(queryData.wordpressPage)

  return (
    <Layout>
      <SEO title={title} meta={meta} />
      <Hero data={hero} />
      <Section data={under_hero} />
    </Layout>
  )
}

export default AboutPage

// create page query with wodpress_id as a variable
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      acf {
        about {
          hero {
            title
            text
            button {
              btn_label
              btn_link
            }
            bg {
              ...ImageFullWidth
            }
          }
          under_hero {
            title
            text
            button {
              btn_link
              btn_label
            }
            image {
              ...Image
            }
          }
        }
      }
      ...SEOData
    }
  }
`
